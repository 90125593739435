
import PropTypes from "prop-types";
import React from 'react'
import Helmet from 'react-helmet'
import { graphql } from 'gatsby'
import Layout from '../../components/Layout'
import Search from "../../components/Search";

const SearchPage = props => {

  const {
    data: {
      site: {
        siteMetadata: { title, algolia }
      }
    }
  } = props;

  return (
    <Layout>
      <section className="section">
        <Helmet title={`Search | ${title}`} />
        <div className="container content">
          <div className="columns">
            <div
              className="column is-10 is-offset-1"
              style={{ marginBottom: '6rem' }}
            >
              <h1 className="title is-size-2 is-bold-light">Search</h1>
              <Search algolia={algolia} />
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}

SearchPage.propTypes = {
  data: PropTypes.object.isRequired
};

export default SearchPage

export const searchPageQuery = graphql`
  query SearchQuery {
    site {
      siteMetadata {
        title
        algolia {
          appId
          searchOnlyApiKey
          indexName
        }
      }
    }
  }
`
